import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as reportsService from 'src/services/ReportsService'

// ROUTINES

export const getPointsReportRoutine = createRoutine('GET_POINTS_REPORT')
export const getAppreciationsReportRoutine = createRoutine(
  'GET_APPRECIATIONS_REPORT'
)

// ACTIONS

function* getPointsReport({ payload }) {
  yield put(getPointsReportRoutine.request())
  try {
    const result = yield call(reportsService.getPointsReport, payload)
    yield put(getPointsReportRoutine.success(result.data))
  } catch (e) {
    yield put(getPointsReportRoutine.failure(e))
    console.error(e)
  }
}

function* getAppreciationsReport({ payload }) {
  yield put(getAppreciationsReportRoutine.request())
  try {
    const result = yield call(reportsService.getAppreciationsReport, payload)
    yield put(getAppreciationsReportRoutine.success(result.data))
  } catch (e) {
    yield put(getAppreciationsReportRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function* getPointsReportWatcher() {
  yield takeLatest(getPointsReportRoutine.TRIGGER, getPointsReport)
}

export function* getAppreciationsReportWatcher() {
  yield takeLatest(
    getAppreciationsReportRoutine.TRIGGER,
    getAppreciationsReport
  )
}

// SAGAS

export const reportsSagas = [
  fork(getPointsReportWatcher),
  fork(getAppreciationsReportWatcher)
]
