import { API_STATES } from 'src/utils/api'
import { pathOr, propEq, propOr, pipe } from 'ramda'
import { createSelector } from 'reselect'

export const selectOrganisation = state => state.organisation

export const isOrganisationLoading = createSelector(
  selectOrganisation,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const getOrganisationDetails = createSelector(
  selectOrganisation,
  propOr({}, 'details')
)

export const getHasPointSystem = createSelector(
  selectOrganisation,
  pathOr(false, ['details', 'settings', 'pointsEnabled'])
)

export const getOrganisationPointsSingularName = createSelector(
  selectOrganisation,
  pathOr('point', ['details', 'settings', 'pointsNameSingular'])
)

export const getOrganisationPointsPluralName = createSelector(
  selectOrganisation,
  pathOr('points', ['details', 'settings', 'pointsNamePlural'])
)

export const getOrganisationAvailableReactions = createSelector(
  selectOrganisation,
  pathOr([], ['details', 'settings', 'reactions'])
)

export const getOrganisationPointsGiveMinimum = createSelector(
  selectOrganisation,
  pipe(
    pathOr(0, ['details', 'settings', 'pointsGiveMinimum']),
    Number
  )
)
