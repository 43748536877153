import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import { hideToastRoutine } from 'src/features/toast/duck/actions'

import { resendVerificationEmail } from 'src/services/AuthService'

export const ResendVerificationEmailNotification = props => {
  //  REDUX
  const dispatch = useDispatch()
  const closeToast = useCallback(() => dispatch(hideToastRoutine()), [dispatch])

  const handleResendEmail = async () => {
    await resendVerificationEmail({ email: props.email })
    return closeToast()
  }

  return (
    <Trans i18nKey='toast.userEmailNotVerified'>
      0 <ResendButton onClick={handleResendEmail}>1</ResendButton> 2
    </Trans>
  )
}

export default ResendVerificationEmailNotification

const ResendButton = styled.span`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`
