import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      dustyGray: '#979797',
      dustyGrayTransparent: '#9797973b',
      slateGray: '#788995',
      alto: '#d8d8d8',
      gallery: '#f0f0f0',
      hurricane: '#847e7d',
      mystic: '#dee3ec',
      mercury: '#e9e9e9',
      gray: '#838383',
      catskillWhite: '#F4F7FA',
      text: '#282166',
      doveGray: '#707070',
      mineShaft: '#2c2c2c',
      whisper: '#f5f6fa',
      osloGray: '#868789',
      titanWhite: '#fefeff'
    },
    background: {
      paper: '#fff',
      default: '#fafafa'
    },
    primary: {
      main: '#ff9347',
      light: '#ffb37d',
      dark: '#fc6e08',
      contrastText: '#fff',
      transparent: '#ffb06b26'
    },
    secondary: {
      main: '#8653D0',
      light: '#A27DD9',
      dark: '#62399c',
      contrastText: '#fff'
    },
    tertiary: {
      main: '#2ebec4',
      light: '#5adbe0',
      dark: '#1d9ca0',
      contrastText: '#fff'
    },
    error: {
      light: '#FF7F69',
      main: '#FF4538',
      dark: '#DB282C',
      contrastText: '#fff'
    },
    warning: {
      light: '#FFE882',
      main: '#ffdd59',
      dark: '#DBB841',
      contrastText: '#282166'
    },
    info: {
      light: '#36DBD2',
      main: '#00bec4',
      dark: '#0095A8',
      contrastText: '#fff'
    },
    success: {
      light: '#A8EF8E',
      main: '#82E569',
      dark: '#5CC44C',
      contrastText: '#282166'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider: 'rgba(0, 0, 0, 0.12)'
  },
  shape: {
    borderRadius: '4px'
  },
  shadowBase: 'rgba(37, 38, 94, 0.1)',
  shadows: [
    'none',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)',
    '4px 0 20px 0 rgba(37, 38, 94, 0.1)'
  ],
  overrides: {
    MuiSelect: {
      select: {
        minWidth: '120px'
      }
    },
    MuiButton: {
      root: {
        height: 48,
        textTransform: 'none',
        fontWeight: '400',
        fontSize: '14px',
        letterSpacing: 'normal',
        lineHeight: 1.5
      },
      sizeLarge: {
        height: 60
      },
      sizeSmall: {
        height: 32
      },
      contained: {
        '&:disabled': {
          opacity: 0.7
        }
      },
      containedSecondary: {
        '&:disabled': {
          backgroundColor: '#8653D0',
          color: '#fff',
          opacity: 0.4
        }
      }
    },
    MuiFormControl: {
      root: {
        margin: '10px 0'
      }
    },
    MuiList: {
      padding: {
        paddingTop: '5px',
        paddingBottom: '5px'
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: '12px',
        paddingBottom: '12px'
      }
    }
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    menu: 1350
  },
  typography: {
    fontFamily: ['Montserrat', 'Open Sans', 'sans-serif']
  }
})
