import React from 'react'

import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'

import createSagaMiddleware from 'redux-saga'

import rootReducer, { rootSaga } from 'src/ducks/rootReducer'
import { identity, compose } from 'ramda'

export default ({ element }) => {
  const middleware = createSagaMiddleware()
  const loggerMiddleware = createLogger({ collapsed: true })

  const middlewares = [middleware]

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(loggerMiddleware)
  }

  const reduxDevtools =
    typeof window !== `undefined` && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : identity()

  const enhancer = compose(applyMiddleware(...middlewares), reduxDevtools)

  const store = createStore(rootReducer, enhancer)

  middleware.run(rootSaga)

  return <Provider store={store}>{element}</Provider>
}
