import { Record } from 'immutable'

export const Toast = Record({
  visible: false,
  key: '',
  options: {},
  severity: '',
  transComponent: null,
  transProps: {}
})
