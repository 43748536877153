import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isNil } from 'ramda'

import { hideToastRoutine } from 'src/features/toast/duck/actions'
import {
  getToastKey,
  getToastOptions,
  getToastSeverity,
  getToastTransComponent,
  getToastTransProps,
  isToastVisible
} from 'src/features/toast/duck/selectors'

import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

export const Toast = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(isToastVisible)
  const translationKey = useSelector(getToastKey)
  const options = useSelector(getToastOptions)
  const severity = useSelector(getToastSeverity)
  const TransComponent = useSelector(getToastTransComponent)
  const transProps = useSelector(getToastTransProps)
  const handleClose = useCallback(() => dispatch(hideToastRoutine()), [
    dispatch
  ])

  const showToastContent = isNil(TransComponent) ? (
    t(translationKey, options)
  ) : (
    <TransComponent {...transProps} />
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={visible}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        elevation={6}
        variant='filled'
        severity={severity}
      >
        <div>{showToastContent}</div>
      </Alert>
    </Snackbar>
  )
}

export default Toast
