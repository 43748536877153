import APIService from 'src/services/APIService'

const api = APIService()

export const getOrganisationDetails = () => api.get('/organisations/profile')

/**
 * Changes organisation details
 * @param {Object} payload - request data object
 * @param {string} [payload.name] - organisation name
 * @param {string} [payload.industry] - organisation industry
 * @param {string} [payload.country] - organisation country
 * @param {string} [payload.city] - organisation city
 * @param {string} [payload.address] - organisation address
 * @param {string} [payload.logoUrl] - organisation logoUrl
 */
export const updateOrganisationDetails = payload =>
  api.patch('/organisations/profile', payload)

/**
 * Changes organisation settings
 * @param {Object} payload - request data object
 * @param {boolean} [payload.pointsEnabled] - organisation points system
 * @param {number} [payload.pointsAmount] - organisation points monthly amount
 * @param {string} [payload.pointsName] - organisation points name
 * @param {string} [payload.language] - organisation default language
 */
export const updateOrganisationSettings = payload =>
  api.patch('/organisations/settings', payload)

export const deleteIntegration = payload =>
  api.delete(`/organisation-integrations/admin/${payload.type}`)
