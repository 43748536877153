import {
  OrganisationDetails,
  Organisation
} from 'src/features/organisation/duck/records'
import {
  getOrganisationDetailsRoutine,
  updateOrganisationSettingsRoutine,
  updateOrganisationDetailsRoutine
} from 'src/features/organisation/duck/actions'
import { API_STATES } from 'src/utils/api'

export default (state = new Organisation(), action) => {
  switch (action.type) {
    case getOrganisationDetailsRoutine.REQUEST:
    case updateOrganisationSettingsRoutine.REQUEST:
    case updateOrganisationDetailsRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getOrganisationDetailsRoutine.FAILURE:
    case updateOrganisationSettingsRoutine.FAILURE:
    case updateOrganisationDetailsRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case getOrganisationDetailsRoutine.SUCCESS:
    case updateOrganisationSettingsRoutine.SUCCESS:
    case updateOrganisationDetailsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('details', new OrganisationDetails(action.payload))
    default:
      return state
  }
}
