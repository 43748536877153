import { mergeDeepRight, pathOr, pipe, includes } from 'ramda'
import axios from 'axios'
import LocalStorageService from 'src/services/LocalStorageService'
import { KEYS } from 'src/utils/localStorage'
import eventEmitter from 'src/providers/eventEmitter'
import events from 'src/utils/events'
import errors, { getErrorName } from 'src/utils/errors'

const apiUrl = process.env.GATSBY_API_URL

export const mergeConfig = config => {
  const authToken = LocalStorageService.get(KEYS.token)
  const authHeader = authToken ? { Authorization: authToken } : {}

  const defaultConfig = {
    headers: {
      Pragma: 'no-cache',
      ...authHeader
    }
  }

  return mergeDeepRight(defaultConfig)(config)
}

const get = instance => async (url, config = {}) => {
  return instance.get(url, mergeConfig(config))
}

const post = instance => async (url, data = {}, config = {}) => {
  return instance.post(url, data, mergeConfig(config))
}

const put = instance => async (url, data = {}, config = {}) => {
  return instance.put(url, data, mergeConfig(config))
}

const patch = instance => async (url, data = {}, config = {}) => {
  return instance.patch(url, data, mergeConfig(config))
}

const handleDelete = instance => async (url, config = {}) => {
  return instance.delete(url, mergeConfig(config))
}

export default (baseURL = apiUrl) => {
  const instance = axios.create({ baseURL })
  instance.interceptors.response.use(
    successRes => successRes,
    error => {
      const isOrganisationsList = pipe(
        pathOr('', ['response', 'config', 'url']),
        includes('/users/organisation')
      )(error)

      if ((getErrorName(error) === errors.accountInactive || getErrorName(error) === errors.entityNotFound) && !isOrganisationsList) {
        eventEmitter.emit(events.logoutInactiveUser)
      }

      return Promise.reject(error)
    }
  )

  return {
    get: get(instance),
    post: post(instance),
    put: put(instance),
    patch: patch(instance),
    delete: handleDelete(instance)
  }
}
