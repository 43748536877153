import { createRoutine } from 'redux-saga-routines'
import { fetchAuthUserRoutine } from 'src/features/auth/duck/actions'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as organisationService from 'src/services/OrganisationService'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import { SEVERITY } from 'src/utils/toast'

// ROUTINES

export const getOrganisationDetailsRoutine = createRoutine(
  'GET_ORGANISATION_DETAILS'
)
export const updateOrganisationDetailsRoutine = createRoutine(
  'UPDATE_ORGANISATION_DETAILS'
)
export const updateOrganisationSettingsRoutine = createRoutine(
  'UPDATE_ORGANISATION_SETTINGS'
)

// ACTIONS

function* getOrganisationDetails() {
  yield put(getOrganisationDetailsRoutine.request())
  try {
    const result = yield call(organisationService.getOrganisationDetails)
    yield put(getOrganisationDetailsRoutine.success(result.data))
  } catch (e) {
    yield put(getOrganisationDetailsRoutine.failure(e))
    console.error(e)
  }
}

function* updateOrganisationDetails({ payload }) {
  yield put(updateOrganisationDetailsRoutine.request())
  try {
    const result = yield call(
      organisationService.updateOrganisationDetails,
      payload
    )
    yield put(updateOrganisationDetailsRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.updateOrganisationDetailsSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(updateOrganisationDetailsRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function* updateOrganisationSettings({ payload }) {
  yield put(updateOrganisationSettingsRoutine.request())
  try {
    yield call(
      organisationService.updateOrganisationSettings,
      payload
    )
    yield put(fetchAuthUserRoutine())
    yield put(getOrganisationDetailsRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.updateOrganisationSettingsSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(updateOrganisationSettingsRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

// WATCHERS

export function* getOrganisationDetailsWatcher() {
  yield takeLatest(
    getOrganisationDetailsRoutine.TRIGGER,
    getOrganisationDetails
  )
}

export function* updateOrganisationDetailsWatcher() {
  yield takeLatest(
    updateOrganisationDetailsRoutine.TRIGGER,
    updateOrganisationDetails
  )
}

export function* updateOrganisationSettingsWatcher() {
  yield takeLatest(
    updateOrganisationSettingsRoutine.TRIGGER,
    updateOrganisationSettings
  )
}

// SAGAS

export const organisationSagas = [
  fork(getOrganisationDetailsWatcher),
  fork(updateOrganisationDetailsWatcher),
  fork(updateOrganisationSettingsWatcher)
]
