// Workaround for build error https://github.com/gatsbyjs/gatsby/issues/309#issuecomment-347595352

const windowSchema = {
  onclick: null,
  scrollY: 0,
  addEventListener: () => {},
  removeEventListener: () => {},
  getSelection: () => {
    return { removeAllRanges: () => {}, addRange: () => {} }
  },
  document: {
    body: {
      createTextRange: () => {
        return { moveToElementText: () => {}, select: () => {} }
      }
    },
    execCommand: () => {},
    getElementById: () => {},
    createRange: () => {
      return { selectNodeContents: () => {}, selectNode: () => {} }
    }
  },
  localStorage: {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
    clear: () => {}
  }
}

export const { localStorage, document, getSelection, addEventListener, removeEventListener } =
  typeof window !== 'undefined' ? window : windowSchema

export const windowElement = typeof window !== 'undefined' ? window : windowSchema
