import {
  Reports,
  PointsReport,
  AppreciationsReport
} from 'src/features/reports/duck/records'
import {
  getPointsReportRoutine,
  getAppreciationsReportRoutine
} from 'src/features/reports/duck/actions'
import { API_STATES } from 'src/utils/api'

export default (state = new Reports(), action) => {
  switch (action.type) {
    case getPointsReportRoutine.REQUEST:
    case getAppreciationsReportRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case getPointsReportRoutine.FAILURE:
    case getAppreciationsReportRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case getPointsReportRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('pointsReport', new PointsReport(action.payload))
    case getAppreciationsReportRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('appreciationsReport', new AppreciationsReport(action.payload))
    default:
      return state
  }
}
