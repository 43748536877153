import { pathOr, pipe, head, propOr } from 'ramda'

export default {
  invalidEntity: 'entity.invalid',
  userExists: 'user.exists',
  entityNotFound: 'entity.not-found',
  userEmailNotVerified: 'user.email.unverified',
  notEnoughPoints: 'appreciation.not-enough-points',
  lowerThanMinimumPoints: 'appreciation.give-min-points',
  accountInactive: 'user.inactive'
}

export const EMAIL_VALIDATION_ERRORS = {
  pendingInvitation: 422,
  emailAlreadyExists: 400
}

export const getErrorName = error =>
  pathOr('', ['response', 'data', 'name'], error)

export const getEmailValidationErrorCode = error =>
  pipe(
    pathOr('', ['response', 'data', 'message']),
    head,
    propOr(null, 'code')
  )(error)

export const getOrganisationInviteIdFromError = error =>
  pipe(
    pathOr('', ['response', 'data', 'message']),
    head,
    propOr(null, 'inviteId')
  )(error)
