import APIService from 'src/services/APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const getOrganisationUsers = query =>
  api.get(`/users/organisation?${query}`)

export const searchOrganisationUsers = query =>
  api.get(`/users/organisation/search?pattern=${query}`)

export const deleteUser = id => api.delete(`/users/${id}`)

export const toggleUserActivity = payload =>
  api.patch(`/users/${payload.id}/toggle-activity`, {
    isActive: payload.isActive
  })

export const updateUserRole = payload =>
  api.patch(`/users/${payload.id}/role`, dissoc('id', payload))

export const updateUser = payload =>
  api.patch(`/users/${payload.id}/profile`, dissoc('id', payload))
