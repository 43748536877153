// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-reset-password-js": () => import("./../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sign-up-js": () => import("./../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-verify-js": () => import("./../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */),
  "component---src-pages-wco-js": () => import("./../../src/pages/WCO.js" /* webpackChunkName: "component---src-pages-wco-js" */),
  "component---src-pages-workspace-js": () => import("./../../src/pages/workspace.js" /* webpackChunkName: "component---src-pages-workspace-js" */)
}

