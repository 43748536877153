import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'

export const OrganisationDetails = Record({
  id: '',
  name: '',
  industry: '',
  country: '',
  city: null,
  address: null,
  deletedAt: null,
  createdAt: '',
  updatedAt: '',
  logoUrl: null,
  currentPlanId: null,
  settings: {
    pointsEnabled: true,
    pointsAmount: 1000,
    pointsGiveMinimum: 100,
    pointsNameSingular: '',
    pointsNamePlural: '',
    language: '',
    reactions: []
  },
  integrations: {},
  domain: ''
})

export const Organisation = Record({
  state: API_STATES.PRISTINE,
  details: OrganisationDetails()
})
