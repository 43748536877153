import { createSelector } from 'reselect'
import { propOr } from 'ramda'

export const selectToast = state => state.toast

export const isToastVisible = createSelector(
  selectToast,
  propOr(false, 'visible')
)

export const getToastKey = createSelector(selectToast, propOr('', 'key'))

export const getToastOptions = createSelector(
  selectToast,
  propOr({}, 'options')
)

export const getToastSeverity = createSelector(
  selectToast,
  propOr('', 'severity')
)

export const getToastTransComponent = createSelector(
  selectToast,
  propOr(null, 'transComponent')
)

export const getToastTransProps = createSelector(
  selectToast,
  propOr('', 'transProps')
)
