import { values } from 'ramda'

export const transformById = (collection, idKey = 'id') =>
  collection.reduce((hash, entity) => {
    hash[entity[idKey]] = entity

    return hash
  }, {})

export const transformToArray = values

export const transferCollectionToImmutableById = (
  collection,
  ImmutableRecord
) => transformById(collection.map(entity => new ImmutableRecord(entity)))

export const transferEntityToImmutableById = (entity, ImmutableRecord) => {
  const convertedRecord = new ImmutableRecord(entity)
  return {
    [convertedRecord.id]: convertedRecord
  }
}
