import { createRoutine } from 'redux-saga-routines'
import {
  put,
  call,
  takeLatest,
  fork
} from '@redux-saga/core/effects'
import * as coreValuesService from 'src/services/coreValuesService'
import { pathOr } from 'ramda';
import { showToastRoutine } from 'src/features/toast/duck/actions'
import { SEVERITY } from 'src/utils/toast'

// ROUTINES

export const createCoreValueRoutine = createRoutine('CREATE_CORE_VALUE')
export const deleteCoreValueRoutine = createRoutine('DELETE_CORE_VALUE')
export const updateCoreValueRoutine = createRoutine('UPDATE_CORE_VALUE')
export const fetchCoreValuesRoutine = createRoutine('FETCH_CORE_VALUES')

// ACTIONS

function* fetchCoreValues () {
  yield put(fetchCoreValuesRoutine.request())
  try {
    const result = yield call(coreValuesService.fetchCoreValues)
    yield put(fetchCoreValuesRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(fetchCoreValuesRoutine.failure())
    console.error(e)
  }
}

function* createCoreValue ({ payload }) {
  yield put(createCoreValueRoutine.request())
  try {
    const result = yield call(coreValuesService.createCoreValue, payload)
    yield put(createCoreValueRoutine.success(pathOr({}, ['data'], result)))
    yield put(fetchCoreValuesRoutine())
    yield put(
      showToastRoutine({
        key: 'toast.createCoreValueSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    console.error(e)
    yield put(createCoreValueRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function* deleteCoreValue ({ payload }) {
  yield put(deleteCoreValueRoutine.request())
  try {
    yield call(coreValuesService.deleteCoreValue, payload)
    yield put(deleteCoreValueRoutine.success(payload))
    yield put(fetchCoreValuesRoutine())
  } catch (e) {
    yield put(deleteCoreValueRoutine.failure(payload))
    console.error(e)
  }
}

function* updateCoreValue ({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateCoreValueRoutine.request())
  try {
    const result = yield call(coreValuesService.updateCoreValue, values)
    yield put(updateCoreValueRoutine.success(pathOr({}, ['data'], result)))
    yield put(
      showToastRoutine({
        key: 'toast.updateCoreValueSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchCoreValuesRoutine())
    callback()
  } catch (e) {
    console.error(e)
    yield put(updateCoreValueRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

// WATCHERS

export function* createCoreValueWatcher() {
  yield takeLatest(createCoreValueRoutine.TRIGGER, createCoreValue)
}

export function* fetchCoreValuesWatcher() {
  yield takeLatest(fetchCoreValuesRoutine.TRIGGER, fetchCoreValues)
}

export function* deleteCoreValueWatcher() {
  yield takeLatest(deleteCoreValueRoutine.TRIGGER, deleteCoreValue)
}

export function* updateCoreValueWatcher() {
  yield takeLatest(updateCoreValueRoutine.TRIGGER, updateCoreValue)
}

// SAGAS

export const coreValuesSagas = [
  fork(createCoreValueWatcher),
  fork(fetchCoreValuesWatcher),
  fork(deleteCoreValueWatcher),
  fork(updateCoreValueWatcher)
]
