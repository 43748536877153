import APIService from 'src/services/APIService'

const api = APIService()

/**
 * Create new appreciation object.
 * @param {Object} payload - new appreciation data
 * @param {array} payload.receivers - array of receivers ids
 * @param {string} payload.content - the reason of appreciation
 * @param {string} [payload.points] - amount of points given to each receiver
 */
export const createAppreciation = payload => api.post('/appreciations', payload)

export const fetchAllAppreciations = query => api.get(`/appreciations?${query}`)

export const fetchGivenByAUthUserAppreciations = query =>
  api.get(`/appreciations/giver?${query}`)

export const fetchReceivedByAuthUserAppreciations = query =>
  api.get(`/appreciations/receiver?${query}`)

/**
 * Create new reaction object.
 * @param {Object} payload - new reaction data
 * @param {string} payload.content - reaction content
 * @param {string} payload.appreciationId - id of appreciation for which user want to react
 */
export const createReactionToAppreciation = payload =>
  api.post(`/appreciations/${payload.appreciationId}/reaction`, {
    content: payload.content
  })

/**
 * Remove reaction.
 * @param {Object} payload - new reaction data
 * @param {string} payload.id - reaction id to remove
 */
export const removeReactionFromAppreciation = payload =>
  api.delete(`/appreciations/reaction/${payload.id}`)

export const fetchUserAppreciationCounters = () =>
  api.get('/users/appreciation-counters')
