import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'

export const Appreciation = Record({
  id: '',
  giverId: '',
  content: '',
  createdAt: '',
  updatedAt: '',
  points: 0,
  giver: {},
  receivers: [],
  reactions: [],
  coreValues: []
})

export const MetaAppreciations = Record({
  page: 0,
  take: 10,
  recordsTotal: 0,
  pagesTotal: 1
})

export const UserAppreciationsCounters = Record({
  appreciationsReceived: 0,
  appreciationsGiven: 0
})

export const Appreciations = Record({
  state: API_STATES.PRISTINE,
  appreciations: {},
  metaAll: MetaAppreciations(),
  metaReceived: MetaAppreciations(),
  metaGiven: MetaAppreciations(),
  userAppreciationsCounters: UserAppreciationsCounters()
})
