import APIService from 'src/services/APIService'

const api = APIService()

/**
 * Gets points summary report
 * @param {string} payload - year/month string that defines the report period (eg. 2020/04)
 */
export const getPointsReport = payload =>
  api.get(`/organisations/reports/point/${payload}`)

/**
 * Gets appreciation summary report
 * @param {string} payload - year/month string that defines the report period (eg. 2020/04)
 */
export const getAppreciationsReport = payload =>
  api.get(`/organisations/reports/appreciation/${payload}`)
