import APIService from 'src/services/APIService'
import { omit } from 'ramda'

const api = APIService()

/**
 * Creates an invitation to organisation
 * @param {Object} payload - request data object
 * @param {array} payload.emails - array of emails to invite
 * @param {string} payload.role - user role for given emails
 */
export const createInvite = payload =>
  api.post('/organisation-invites', payload)

export const getInvites = query => api.get(`/organisation-invites?${query}`)

/**
 * Validates given emails
 * @param {Object} payload - request data object
 * @param {array} payload.emails - array of emails to validate
 */
export const validateEmail = payload =>
  api.post('/organisation-invites/validate-email', payload)

export const deleteInvitation = id => api.delete(`/organisation-invites/${id}`)

export const getOrganisationInvite = id =>
  api.get(`/organisation-invites/${id}`)

/**
 * Accepts the invitation to organisation
 * @param {Object} payload - request data object
 * @param {string} payload.id - id of the invitation
 * @param {string} payload.firstName - first name of the invited user
 * @param {string} payload.lastName - last name of the invited user
 * @param {string} payload.password - password of the invited user
 * @param {string} [payload.jobFunction] - job function of the invited user
 * @param {string} [payload.phoneNumber] - phone number of the invited user
 */
export const acceptOrganisationInvite = payload =>
  api.post(`/organisation-invites/${payload.id}/accept`, omit(['id'], payload))
