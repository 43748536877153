import { Toast } from 'src/features/toast/duck/records'
import {
  showToastRoutine,
  hideToastRoutine
} from 'src/features/toast/duck/actions'

export default (state = new Toast(), action) => {
  switch (action.type) {
    case showToastRoutine.SUCCESS:
      return new Toast(action.payload)
    case hideToastRoutine.SUCCESS:
      return state.set('visible', false)
    default:
      return state
  }
}
