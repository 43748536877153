import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'

export const PointsReport = Record({
  totalPointsGiven: 0,
  users: {}
})

export const AppreciationsReport = Record({
  totalAppreciationCount: 0,
  totalReactionCount: 0,
  totalAppreciationAndReactionCount: 0,
  users: {}
})

export const Reports = Record({
  state: API_STATES.PRISTINE,
  pointsReport: PointsReport(),
  appreciationsReport: AppreciationsReport()
})
