import {
  Users,
  User,
  MetaUsers,
  OrganisationInvite
} from 'src/features/users/duck/records'
import {
  inviteUsersRoutine,
  getOrganisationInvitesRoutine,
  deleteInvitationRoutine,
  getOrganisationUsersRoutine,
  toggleUserActivityRoutine,
  deleteUserRoutine,
  updateUserRoleRoutine,
  updateUserRoutine
} from 'src/features/users/duck/actions'
import { API_STATES } from 'src/utils/api'
import { transferCollectionToImmutableById } from 'src/utils/redux'
import { mergeRight, dissoc } from 'ramda'

export default (state = new Users(), action) => {
  switch (action.type) {
    case deleteInvitationRoutine.REQUEST:
    case inviteUsersRoutine.REQUEST:
    case getOrganisationInvitesRoutine.REQUEST:
    case getOrganisationUsersRoutine.REQUEST:
    case toggleUserActivityRoutine.REQUEST:
    case deleteUserRoutine.REQUEST:
    case updateUserRoleRoutine.REQUEST:
    case updateUserRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case inviteUsersRoutine.FAILURE:
    case deleteInvitationRoutine.FAILURE:
    case getOrganisationInvitesRoutine.FAILURE:
    case getOrganisationUsersRoutine.FAILURE:
    case toggleUserActivityRoutine.FAILURE:
    case deleteUserRoutine.FAILURE:
    case updateUserRoleRoutine.FAILURE:
    case updateUserRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case deleteInvitationRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'organisationInvites',
          dissoc(action.payload, state.organisationInvites)
        )
    case inviteUsersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'organisationInvites',
          mergeRight(
            state.organisationInvites,
            transferCollectionToImmutableById(
              action.payload,
              OrganisationInvite
            )
          )
        )
    case getOrganisationInvitesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'organisationInvites',
          transferCollectionToImmutableById(
            action.payload.data,
            OrganisationInvite
          )
        )
        .set('metaOrganisationInvites', new MetaUsers(action.payload.meta))
    case getOrganisationUsersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'users',
          transferCollectionToImmutableById(action.payload.data, User)
        )
        .set('metaUsers', new MetaUsers(action.payload.meta))
    case toggleUserActivityRoutine.SUCCESS:
    case updateUserRoleRoutine.SUCCESS:
    case updateUserRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE).set('users', {
        ...state.users,
        [action.payload.id]: new User(action.payload)
      })
    case deleteUserRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('users', dissoc(action.payload, state.users))
    default:
      return state
  }
}
