import { CoreValues, CoreValue } from 'src/features/coreValues/duck/records'
import {
  createCoreValueRoutine,
  updateCoreValueRoutine,
  deleteCoreValueRoutine,
  fetchCoreValuesRoutine
} from 'src/features/coreValues/duck/actions'
import { API_STATES } from 'src/utils/api'
import { transferCollectionToImmutableById } from 'src/utils/redux'

export default (state = new CoreValues(), action) => {
  switch (action.type) {
    case createCoreValueRoutine.REQUEST:
    case updateCoreValueRoutine.REQUEST:
    case deleteCoreValueRoutine.REQUEST:
    case fetchCoreValuesRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createCoreValueRoutine.SUCCESS:
    case updateCoreValueRoutine.SUCCESS:
    case deleteCoreValueRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE)
    case fetchCoreValuesRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'coreValues',
          transferCollectionToImmutableById(action.payload, CoreValue)
        )
    default:
      return state
  }
}
