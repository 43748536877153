import errors, { getErrorName } from 'src/utils/errors'
import ResendVerificationEmailNotification from 'src/features/auth/components/ResendVerificationEmailNotification'
import { SEVERITY } from 'src/utils/toast'

export const getRegisterErrorToastMessage = error => {
  let errorTranslationKey
  switch (getErrorName(error)) {
    case errors.invalidEntity:
      errorTranslationKey = 'toast.invalidEntity'
      break
    case errors.userExists:
      errorTranslationKey = 'toast.emailTaken'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}

export const getLoginErrorToastMessage = (error, email) => {
  let toastPayload = { severity: SEVERITY.error }
  switch (getErrorName(error)) {
    case errors.entityNotFound:
      toastPayload = { ...toastPayload, key: 'toast.emailNotFound' }
      break
    case errors.accountInactive:
      toastPayload = { ...toastPayload, key: 'toast.accountInactive' }
      break
    case errors.userEmailNotVerified:
      toastPayload = {
        ...toastPayload,
        transComponent: ResendVerificationEmailNotification,
        transProps: { email }
      }
      break
    default:
      toastPayload = { ...toastPayload, key: 'toast.loginFailed' }
      break
  }

  return toastPayload
}

export const getAppreciationErrorMessage = error => {
  let errorTranslationKey
  switch (getErrorName(error)) {
    case errors.notEnoughPoints:
      errorTranslationKey = 'toast.notEnoughPoints'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error }
}
