import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'

export const CoreValue = Record({
  id: '',
  name: ''
})

export const CoreValues = Record({
  state: API_STATES.PRISTINE,
  coreValues: {}
})
