import { createRoutine } from 'redux-saga-routines'
import { navigate } from 'gatsby'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import { pathOr } from 'ramda'
import * as authService from 'src/services/AuthService'
import * as organisationInvitesService from 'src/services/OrganisationInvitesService'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import PATHS from 'src/utils/paths'
import LocalStorageService from 'src/services/LocalStorageService'
import { KEYS } from 'src/utils/localStorage'
import {
  getRegisterErrorToastMessage,
  getLoginErrorToastMessage
} from 'src/features/auth/duck/errors'
import { SEVERITY } from 'src/utils/toast'

// ROUTINES

export const registerUserRoutine = createRoutine('REGISTER_USER')
export const loginUserRoutine = createRoutine('LOGIN_USER')
export const logoutUserRoutine = createRoutine('LOGOUT_USER')
export const setRegistrationEmailRoutine = createRoutine('REGISTRATION_EMAIL')
export const getOrganisationInviteRoutine = createRoutine(
  'GET_ORGANISATION_INVITE'
)
export const removeOrganisationInviteDataRoutine = createRoutine(
  'REMOVE_ORGANISATION_INVITE_DATA'
)

export const acceptOrganisationInviteRoutine = createRoutine(
  'ACCEPT_ORGANISATION_INVITE_ROUTINE'
)
export const fetchAuthUserRoutine = createRoutine('FETCH_AUTH_USER')
export const updateAuthUserRoutine = createRoutine('UPDATE_AUTH_USER')
export const verifyUserEmailRoutine = createRoutine('VERIFY_USER_EMAIL')
export const fetchPointsSummaryRoutine = createRoutine('FETCH_POINTS_SUMMARY')

// ACTIONS

function* registerUser({ payload }) {
  yield put(registerUserRoutine.request())
  try {
    const result = yield call(authService.registerUser, payload)
    yield put(registerUserRoutine.success(pathOr({}, ['data', 'data'], result)))
    yield put(
      showToastRoutine({
        key: 'toast.registerSuccess',
        severity: SEVERITY.success
      })
    )
    navigate(PATHS.logIn)
  } catch (e) {
    yield put(registerUserRoutine.failure(e))
    yield put(showToastRoutine(getRegisterErrorToastMessage(e)))
    console.error(e)
  }
}

function* loginUser({ payload }) {
  yield put(loginUserRoutine.request())
  try {
    const result = yield call(authService.loginUser, payload)
    if (result.data.token) {
      LocalStorageService.set(KEYS.token, result.data.token)
    }
    yield put(loginUserRoutine.success())
    navigate(PATHS.workspaceDashboard)
  } catch (e) {
    yield put(loginUserRoutine.failure(e))
    yield put(showToastRoutine(getLoginErrorToastMessage(e, payload.email)))
  }
}

function* logoutUser() {
  yield put(logoutUserRoutine.request())
  LocalStorageService.remove(KEYS.token)
  yield put(logoutUserRoutine.success())
  navigate(PATHS.logIn)
}

function* setRegistrationEmail({ payload }) {
  yield put(setRegistrationEmailRoutine.request())
  try {
    yield put(setRegistrationEmailRoutine.success(payload))
  } catch (e) {
    yield put(setRegistrationEmailRoutine.failure(e))
  }
}

function* getOrganisationInvite({ payload }) {
  yield put(getOrganisationInviteRoutine.request())
  try {
    const result = yield call(
      organisationInvitesService.getOrganisationInvite,
      payload
    )
    yield put(getOrganisationInviteRoutine.success(result.data))
  } catch (e) {
    yield put(getOrganisationInviteRoutine.failure(e))
  }
}

function* removeOrganisationInviteData() {
  yield put(removeOrganisationInviteDataRoutine.request())
  try {
    yield put(removeOrganisationInviteDataRoutine.success())
  } catch (e) {
    yield put(removeOrganisationInviteDataRoutine.failure(e))
  }
}

function* acceptOrganisationInvite({ payload }) {
  yield put(acceptOrganisationInviteRoutine.request())
  try {
    const result = yield call(
      organisationInvitesService.acceptOrganisationInvite,
      payload
    )
    if (result.data.token) {
      LocalStorageService.set(KEYS.token, result.data.token)
    }
    yield put(acceptOrganisationInviteRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.organisationInviteAcceptSuccess',
        severity: SEVERITY.success
      })
    )
    navigate(PATHS.workspaceDashboard)
  } catch (e) {
    yield put(acceptOrganisationInviteRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function* fetchAuthUser() {
  yield put(fetchAuthUserRoutine.request())
  try {
    const result = yield call(authService.fetchAuthUser)
    yield put(fetchAuthUserRoutine.success(result.data))
  } catch (e) {
    yield put(fetchAuthUserRoutine.failure(e))
  }
}

function* updateAuthUser({ payload }) {
  yield put(updateAuthUserRoutine.request())
  try {
    const result = yield call(authService.updateAuthUser, payload)
    yield put(updateAuthUserRoutine.success(result.data))
    yield put(
      showToastRoutine({
        key: 'toast.updateProfileSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(updateAuthUserRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
  }
}

function* verifyUserEmail({ payload }) {
  yield put(verifyUserEmailRoutine.request())
  try {
    const result = yield call(authService.verifyEmail, payload)
    if (result.data.token) {
      LocalStorageService.set(KEYS.token, result.data.token)
    }
    yield put(verifyUserEmailRoutine.success())
    yield put(
      showToastRoutine({
        key: 'toast.verifyEmailSuccess',
        severity: SEVERITY.success
      })
    )
    navigate(PATHS.workspaceDashboard)
  } catch (e) {
    yield put(verifyUserEmailRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    navigate(PATHS.homepage)
    console.error(e)
  }
}

function* fetchPointsSummary() {
  yield put(fetchPointsSummaryRoutine.request())
  try {
    const result = yield call(authService.fetchPointSummary)
    yield put(fetchPointsSummaryRoutine.success(result.data))
  } catch (e) {
    yield put(fetchPointsSummaryRoutine.failure(e))
  }
}

// WATCHERS

export function* registerUserWatcher() {
  yield takeLatest(registerUserRoutine.TRIGGER, registerUser)
}

export function* loginUserWatcher() {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}

export function* logoutUserWatcher() {
  yield takeLatest(logoutUserRoutine.TRIGGER, logoutUser)
}

export function* setRegistrationEmailWatcher() {
  yield takeLatest(setRegistrationEmailRoutine.TRIGGER, setRegistrationEmail)
}

export function* getOrganisationInviteWatcher() {
  yield takeLatest(getOrganisationInviteRoutine.TRIGGER, getOrganisationInvite)
}

export function* removeOrganisationInviteDataWatcher() {
  yield takeLatest(
    removeOrganisationInviteDataRoutine.TRIGGER,
    removeOrganisationInviteData
  )
}

export function* acceptOrganisationInviteWatcher() {
  yield takeLatest(
    acceptOrganisationInviteRoutine.TRIGGER,
    acceptOrganisationInvite
  )
}

export function* fetchAuthUserWatcher() {
  yield takeLatest(fetchAuthUserRoutine.TRIGGER, fetchAuthUser)
}

export function* updateAuthUserWatcher() {
  yield takeLatest(updateAuthUserRoutine.TRIGGER, updateAuthUser)
}

export function* verifyUserEmailWatcher() {
  yield takeLatest(verifyUserEmailRoutine.TRIGGER, verifyUserEmail)
}

export function* fetchPointsSummaryWatcher() {
  yield takeLatest(fetchPointsSummaryRoutine.TRIGGER, fetchPointsSummary)
}

// SAGAS

export const authSagas = [
  fork(registerUserWatcher),
  fork(loginUserWatcher),
  fork(logoutUserWatcher),
  fork(setRegistrationEmailWatcher),
  fork(getOrganisationInviteWatcher),
  fork(removeOrganisationInviteDataWatcher),
  fork(acceptOrganisationInviteWatcher),
  fork(fetchAuthUserWatcher),
  fork(updateAuthUserWatcher),
  fork(verifyUserEmailWatcher),
  fork(fetchPointsSummaryWatcher)
]
