import errors, { getErrorName } from 'src/utils/errors'
import { SEVERITY } from 'src/utils/toast'

export const getAppreciationErrorMessage = (error, options = {}) => {
  let errorTranslationKey
  switch (getErrorName(error)) {
    case errors.notEnoughPoints:
      errorTranslationKey = 'toast.notEnoughPoints'
      break
    case errors.lowerThanMinimumPoints:
      errorTranslationKey = 'toast.lowerThanMinimumPoints'
      break
    default:
      errorTranslationKey = 'toast.somethingWentWrong'
      break
  }

  return { key: errorTranslationKey, severity: SEVERITY.error, options }
}
