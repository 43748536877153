export default {
  homepage: '/',
  signUp: '/sign-up/email',
  signUpNewUser: '/sign-up/new',
  signUpInvitedUser: '/sign-up/invite/:organisationInviteId',
  logIn: '/login',
  logout: '/logout',
  verifyEmail: '/verify/:id/:token',
  workspaceDashboard: '/workspace/appreciations',
  workspaceUsers: '/workspace/users',
  organisationSettings: '/workspace/organisation-settings',
  workspaceMyAccount: '/workspace/my-account',
  workspaceMyProfile: '/workspace/my-profile',
  workspaceIntegrations: '/workspace/integrations',
  workspaceReports: '/workspace/reports',
  resetPasswordInit: '/reset-password/init',
  resetPasswordFinish: '/reset-password/:id/:token'
}

export const LANDING_PAGE_SECTIONS = {
  howItWorksSection: 'how-it-works-section',
  benefitsSection: 'benefits-section',
  aboutSection: 'about-section',
  demoSection: 'demo-section',
  contactSection: 'contact-section'
}
