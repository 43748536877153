import {
  Appreciations,
  Appreciation,
  MetaAppreciations,
  UserAppreciationsCounters
} from 'src/features/appreciations/duck/records'
import {
  createAppreciationRoutine,
  fetchAllAppreciationsRoutine,
  fetchGivenByAuthUserAppreciationsRoutine,
  fetchReceivedByAuthUserAppreciationsRoutine,
  createReactionToAppreciationRoutine,
  removeReactionFromAppreciationRoutine,
  fetchUserAppreciationCountersRoutine
} from 'src/features/appreciations/duck/actions'
import { API_STATES } from 'src/utils/api'
import {
  transferEntityToImmutableById,
  transferCollectionToImmutableById
} from 'src/utils/redux'
import { mergeRight, pathOr, propEq, propOr, pipe, filter, not } from 'ramda'

export default (state = new Appreciations(), action) => {
  switch (action.type) {
    case createAppreciationRoutine.REQUEST:
    case fetchAllAppreciationsRoutine.REQUEST:
    case fetchGivenByAuthUserAppreciationsRoutine.REQUEST:
    case fetchReceivedByAuthUserAppreciationsRoutine.REQUEST:
    case fetchUserAppreciationCountersRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case createAppreciationRoutine.FAILURE:
    case fetchAllAppreciationsRoutine.FAILURE:
    case fetchGivenByAuthUserAppreciationsRoutine.FAILURE:
    case fetchReceivedByAuthUserAppreciationsRoutine.FAILURE:
    case fetchUserAppreciationCountersRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case createAppreciationRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'appreciations',
          mergeRight(
            state.appreciations,
            transferEntityToImmutableById(action.payload, Appreciation)
          )
        )
    case fetchAllAppreciationsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'appreciations',
          mergeRight(
            state.appreciations,
            transferCollectionToImmutableById(action.payload.data, Appreciation)
          )
        )
        .set('metaAll', new MetaAppreciations(action.payload.meta))
    case fetchGivenByAuthUserAppreciationsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'appreciations',
          mergeRight(
            state.appreciations,
            transferCollectionToImmutableById(action.payload.data, Appreciation)
          )
        )
        .set('metaGiven', new MetaAppreciations(action.payload.meta))
    case fetchReceivedByAuthUserAppreciationsRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'appreciations',
          mergeRight(
            state.appreciations,
            transferCollectionToImmutableById(action.payload.data, Appreciation)
          )
        )
        .set('metaReceived', new MetaAppreciations(action.payload.meta))
    case createReactionToAppreciationRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE).set('appreciations', {
        ...state.appreciations,
        // Updating appreciation with new reaction
        [action.payload.appreciationId]: new Appreciation({
          ...getCurrentAppreciation(state, action.payload),
          reactions: addNewReactionToAppreciationReactionsArray(
            state,
            action.payload
          )
        })
      })
    case removeReactionFromAppreciationRoutine.SUCCESS:
      return state.set('state', API_STATES.DONE).set('appreciations', {
        ...state.appreciations,
        // Updating appreciation with removed reaction
        [action.payload.appreciationId]: new Appreciation({
          ...getCurrentAppreciation(state, action.payload),
          reactions: removeReactionFromAppreciationReactionsArray(
            state,
            action.payload
          )
        })
      })
    case fetchUserAppreciationCountersRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set(
          'userAppreciationsCounters',
          new UserAppreciationsCounters(action.payload)
        )
    default:
      return state
  }
}

const getCurrentAppreciation = (state, payload) =>
  propOr({}, payload.appreciationId, state.appreciations).toObject()

const addNewReactionToAppreciationReactionsArray = (state, payload) => [
  ...pathOr([], [payload.appreciationId, 'reactions'], state.appreciations),
  payload
]

const removeReactionFromAppreciationReactionsArray = (state, payload) =>
  pipe(
    pathOr([], [payload.appreciationId, 'reactions']),
    filter(pipe(propEq('id', payload.id), not))
  )(state.appreciations)
