import { combineReducers } from 'redux'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { all, fork } from '@redux-saga/core/effects'

// initialStates
import { Auth } from 'src/features/auth/duck/records'
import { Toast } from 'src/features/toast/duck/records'
import { Users } from 'src/features/users/duck/records'
import { Organisation } from 'src/features/organisation/duck/records'
import { Appreciations } from 'src/features/appreciations/duck/records'
import { Reports } from 'src/features/reports/duck/records'
import { CoreValues } from 'src/features/coreValues/duck/records'

// reducers
import authReducer from 'src/features/auth/duck/reducers'
import toastReducer from 'src/features/toast/duck/reducers'
import usersReducer from 'src/features/users/duck/reducers'
import organisationReducer from 'src/features/organisation/duck/reducers'
import appreciationReducer from 'src/features/appreciations/duck/reducers'
import reportsReducer from 'src/features/reports/duck/reducers'
import coreValuesReducer from 'src/features/coreValues/duck/reducers'

// sagas
import { authSagas, logoutUserRoutine } from 'src/features/auth/duck/actions'
import { toastSagas } from 'src/features/toast/duck/actions'
import { usersSagas } from 'src/features/users/duck/actions'
import { organisationSagas } from 'src/features/organisation/duck/actions'
import { appreciationSagas } from 'src/features/appreciations/duck/actions'
import { reportsSagas } from 'src/features/reports/duck/actions'
import { coreValuesSagas } from 'src/features/coreValues/duck/actions'

// reducer
const appReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  users: usersReducer,
  organisation: organisationReducer,
  appreciations: appreciationReducer,
  reports: reportsReducer,
  coreValues: coreValuesReducer
})

const initialReducer = {
  auth: new Auth(),
  toast: new Toast(),
  users: new Users(),
  organisation: new Organisation(),
  appreciations: new Appreciations(),
  reports: new Reports(),
  coreValues: new CoreValues()
}

const rootReducer = (state, action) => {
  if (action.type === logoutUserRoutine.SUCCESS) {
    state = initialReducer
  }

  return appReducer(state, action)
}

export default rootReducer

export function* rootSaga() {
  yield all([
    ...authSagas,
    ...toastSagas,
    ...usersSagas,
    ...organisationSagas,
    ...appreciationSagas,
    ...reportsSagas,
    ...coreValuesSagas,
    fork(routinePromiseWatcherSaga)
  ])
}
