import React from 'react'
import theme from 'src/theme/theme'
import muiTheme from 'src/theme/muiTheme'
import GlobalStyles from 'src/theme/globalStyles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import { ThemeProvider } from 'de-suite-me-ui'
import Toast from 'src/features/toast/Toast'

export default ({ children }) => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <Toast />
        {children}
      </MuiThemeProvider>
    </ThemeProvider>
  </>
)
