import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import { pathOr } from 'ramda'
import * as organisationInvitesService from 'src/services/OrganisationInvitesService'
import * as usersService from 'src/services/UsersService'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import { SEVERITY } from 'src/utils/toast'

// ROUTINES

export const inviteUsersRoutine = createRoutine('INVITE_USERS')
export const getOrganisationInvitesRoutine = createRoutine(
  'GET_ORGANISATION_INVITES_ROUTINE'
)
export const deleteInvitationRoutine = createRoutine(
  'DELETE_INVITATION_ROUTINE'
)
export const getOrganisationUsersRoutine = createRoutine(
  'GET_ORGANISATION_USERS'
)

export const toggleUserActivityRoutine = createRoutine('TOGGLE_USER_ACTIVATION')
export const deleteUserRoutine = createRoutine('DELETE_USER')
export const updateUserRoleRoutine = createRoutine('UPDATE_USER_ROLE')
export const updateUserRoutine = createRoutine('UPDATE_USER')

// ACTIONS

function* createInvite({ payload }) {
  yield put(inviteUsersRoutine.request())
  try {
    const result = yield call(organisationInvitesService.createInvite, payload)
    yield put(inviteUsersRoutine.success(pathOr({}, ['data'], result)))
    yield put(
      showToastRoutine({
        key: 'toast.organisationInviteSent',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(inviteUsersRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function* getOrganisationInvites({ payload }) {
  yield put(getOrganisationInvitesRoutine.request())
  try {
    const result = yield call(organisationInvitesService.getInvites, payload)
    yield put(
      getOrganisationInvitesRoutine.success(pathOr([], ['data'], result))
    )
  } catch (e) {
    yield put(getOrganisationInvitesRoutine.failure(e))
    console.error(e)
  }
}

function* deleteInvitation({ payload }) {
  yield put(deleteInvitationRoutine.request())
  try {
    yield call(organisationInvitesService.deleteInvitation, payload)
    yield put(deleteInvitationRoutine.success(payload))
  } catch (e) {
    yield put(deleteInvitationRoutine.failure(e))
    console.error(e)
  }
}

function* getOrganisationUsers({ payload }) {
  yield put(getOrganisationUsersRoutine.request())
  try {
    const result = yield call(usersService.getOrganisationUsers, payload)
    yield put(getOrganisationUsersRoutine.success(pathOr([], ['data'], result)))
  } catch (e) {
    yield put(getOrganisationUsersRoutine.failure(e))
    console.error(e)
  }
}

function* toggleUserActivity({ payload }) {
  yield put(toggleUserActivityRoutine.request())
  try {
    const result = yield call(usersService.toggleUserActivity, payload)
    yield put(toggleUserActivityRoutine.success(pathOr([], ['data'], result)))
    yield put(
      showToastRoutine({
        key: 'toast.toggleActivitySuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(toggleUserActivityRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function* deleteUser({ payload }) {
  yield put(deleteUserRoutine.request())
  try {
    yield call(usersService.deleteUser, payload)
    yield put(deleteUserRoutine.success(payload))
    yield put(
      showToastRoutine({
        key: 'toast.removeUserSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(deleteUserRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function* updateUserRole({ payload }) {
  yield put(updateUserRoleRoutine.request())
  try {
    const result = yield call(usersService.updateUserRole, payload)
    yield put(updateUserRoleRoutine.success(pathOr([], ['data'], result)))
    yield put(
      showToastRoutine({
        key: 'toast.updateUserRoleSuccess',
        severity: SEVERITY.success
      })
    )
  } catch (e) {
    yield put(updateUserRoleRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

function* updateUser({ payload: { values = {}, callback = () => {} } }) {
  yield put(updateUserRoutine.request())
  try {
    const result = yield call(usersService.updateUser, values)
    yield put(updateUserRoutine.success(pathOr([], ['data'], result)))
    yield put(
      showToastRoutine({
        key: 'toast.updateUserSuccess',
        severity: SEVERITY.success
      })
    )
    callback()
  } catch (e) {
    yield put(updateUserRoutine.failure(e))
    yield put(
      showToastRoutine({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    )
    console.error(e)
  }
}

// WATCHERS

export function* inviteUsersWatcher() {
  yield takeLatest(inviteUsersRoutine.TRIGGER, createInvite)
}

export function* getOrganisationInvitesWatcher() {
  yield takeLatest(
    getOrganisationInvitesRoutine.TRIGGER,
    getOrganisationInvites
  )
}

export function* deleteInvitationWatcher() {
  yield takeLatest(deleteInvitationRoutine.TRIGGER, deleteInvitation)
}

export function* getOrganisationUsersWatcher() {
  yield takeLatest(getOrganisationUsersRoutine.TRIGGER, getOrganisationUsers)
}

export function* toggleUserActivityWatcher() {
  yield takeLatest(toggleUserActivityRoutine.TRIGGER, toggleUserActivity)
}

export function* deleteUserWatcher() {
  yield takeLatest(deleteUserRoutine.TRIGGER, deleteUser)
}

export function* updateUserRoleWatcher() {
  yield takeLatest(updateUserRoleRoutine.TRIGGER, updateUserRole)
}

export function* updateUserWatcher() {
  yield takeLatest(updateUserRoutine.TRIGGER, updateUser)
}

// SAGAS

export const usersSagas = [
  fork(inviteUsersWatcher),
  fork(getOrganisationInvitesWatcher),
  fork(deleteInvitationWatcher),
  fork(getOrganisationUsersWatcher),
  fork(toggleUserActivityWatcher),
  fork(deleteUserWatcher),
  fork(updateUserRoleWatcher),
  fork(updateUserWatcher)
]
