import APIService from 'src/services/APIService'

const api = APIService()

/**
 * Creates new user.
 * @param {Object} payload - new user data
 * @param {string} payload.email - new users email
 * @param {string} payload.firstName - new users first name
 * @param {string} payload.lastName - new users last name
 * @param {string} payload.password - new users password
 * @param {string} payload.jobFunction - new users job function
 * @param {string} [payload.phoneNumber] - new users phone number
 * @param {Object} payload.organisation - new organisation data
 * @param {string} payload.organisation.name - new organisation name
 * @param {string} payload.organisation.industry - new organisation industry
 * @param {string} [payload.organisation.logoUrl] - new organisation logoUrl
 * @param {string} payload.organisation.country - new organisation country code
 * @param {string} [payload.organisation.city] - new organisation city
 * @param {string} [payload.organisation.address] - new organisation address
 */
export const registerUser = payload => api.post('/users', payload)

/**
 * Verifies email address of registered users.
 * @param {Object} payload - verification data
 * @param {string} payload.id - new users id
 * @param {string} payload.token - verification token
 */
export const verifyEmail = payload => api.post('/users/verify', payload)

/**
 * Authenticates users
 * @param {Object} payload - auth data
 * @param {string} payload.email - users email address
 * @param {string} payload.password - users password
 */
export const loginUser = payload => api.post('/users/auth', payload)

/**
 * Re-sends email with verification link
 * @param {Object} payload - resend data
 * @param {string} payload.email - users email address
 */
export const resendVerificationEmail = payload =>
  api.post('/users/resend-verification-email', payload)

/**
 * Sends email address with a link to reset password page
 * @param {Object} payload - request data object
 * @param {string} payload.email - users email address
 */
export const resetPasswordInit = payload =>
  api.post('/users/reset-password-init', payload)

/**
 * Resets user password
 * @param {Object} payload - request data object
 * @param {string} payload.id - users id
 * @param {string} payload.token - reset password token
 * @param {string} payload.password - new password
 */
export const resetPasswordFinish = payload =>
  api.post('/users/reset-password-finish', payload)

export const fetchAuthUser = () => api.get('/users/profile')

/**
 * Changes user details
 * @param {Object} payload - request data object
 * @param {string} [payload.firstName - users first name
 * @param {string} [payload.lastName] - users last name
 * @param {string} [payload.jobFunction] - users job function
 * @param {string} [payload.phoneNumber] - users phone number
 */
export const updateAuthUser = payload => api.patch('/users/profile', payload)

/**
 * Checks if authentication token is still valid
 * @param {Object} payload - validate password payload
 * @param {string} payload.id - users id
 * @param {string} payload.token - reset password token
 */
export const validateResetPasswordToken = payload =>
  api.post('/users/reset-password-validate', payload)

export const fetchPointSummary = () => api.get('/users/point-summary')
export const uploadPhoto = (file) => {
  const formData = new window.FormData()
  formData.append('file', file)

  return api.post('/files/avatar/upload', formData)
}
