import { Auth, User, PointsSummary } from 'src/features/auth/duck/records'
import {
  registerUserRoutine,
  loginUserRoutine,
  logoutUserRoutine,
  setRegistrationEmailRoutine,
  getOrganisationInviteRoutine,
  removeOrganisationInviteDataRoutine,
  acceptOrganisationInviteRoutine,
  fetchAuthUserRoutine,
  updateAuthUserRoutine,
  verifyUserEmailRoutine,
  fetchPointsSummaryRoutine
} from 'src/features/auth/duck/actions'
import { API_STATES } from 'src/utils/api'
import { OrganisationInvite } from 'src/features/users/duck/records'

export default (state = new Auth(), action) => {
  switch (action.type) {
    case fetchAuthUserRoutine.REQUEST:
    case registerUserRoutine.REQUEST:
    case acceptOrganisationInviteRoutine.REQUEST:
    case loginUserRoutine.REQUEST:
    case getOrganisationInviteRoutine.REQUEST:
    case updateAuthUserRoutine.REQUEST:
    case verifyUserEmailRoutine.REQUEST:
    case fetchPointsSummaryRoutine.REQUEST:
      return state.set('state', API_STATES.IN_PROGRESS)
    case registerUserRoutine.SUCCESS:
    case registerUserRoutine.FAILURE:
    case acceptOrganisationInviteRoutine.SUCCESS:
    case acceptOrganisationInviteRoutine.FAILURE:
    case loginUserRoutine.FAILURE:
    case loginUserRoutine.SUCCESS:
    case getOrganisationInviteRoutine.FAILURE:
    case fetchAuthUserRoutine.FAILURE:
    case updateAuthUserRoutine.FAILURE:
    case verifyUserEmailRoutine.SUCCESS:
    case verifyUserEmailRoutine.FAILURE:
    case fetchPointsSummaryRoutine.FAILURE:
      return state.set('state', API_STATES.DONE)
    case setRegistrationEmailRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('registrationEmail', action.payload)
    case getOrganisationInviteRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('organisationInvite', new OrganisationInvite(action.payload))
    case removeOrganisationInviteDataRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('organisationInvite', new OrganisationInvite())
    case fetchAuthUserRoutine.SUCCESS:
    case updateAuthUserRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('user', new User(action.payload))
    case fetchPointsSummaryRoutine.SUCCESS:
      return state
        .set('state', API_STATES.DONE)
        .set('pointsSummary', new PointsSummary(action.payload))
    case logoutUserRoutine.SUCCESS:
      return new Auth()
    default:
      return state
  }
}
