import { Record } from 'immutable'
import { API_STATES } from 'src/utils/api'
import { OrganisationInvite } from 'src/features/users/duck/records'

export const User = Record({
  avatar: null,
  avatarUrl: null,
  email: '',
  firstName: '',
  lastName: '',
  nickname: '',
  phoneNumber: '',
  jobFunction: '',
  isEmailVerified: false,
  isActive: false,
  createdAt: '',
  updatedAt: '',
  id: '',
  deletedAt: null,
  loggedInAt: null,
  role: '',
  organisationId: '',
  settings: {},
  passwordResetTokenCreatedAt: '',
  pointsLeft: 0,
  organisation: {}
})

export const PointsSummary = Record({
  appreciatedBy: 0,
  pointsReceived: 0,
  appreciationsGiven: 0
})

export const Auth = Record({
  state: API_STATES.PRISTINE,
  user: User(),
  pointsSummary: PointsSummary(),
  registrationEmail: null,
  organisationInvite: OrganisationInvite()
})
