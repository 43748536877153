import { localStorage } from 'src/utils/window'

export const storageService = {
  get: key => localStorage.getItem(key),
  set: (key, value) => localStorage.setItem(key, value),
  remove: key => localStorage.removeItem(key),
  clear: () => localStorage.clear()
}

export default storageService
