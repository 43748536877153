import { createRoutine } from 'redux-saga-routines'
import {
  put,
  call,
  takeLatest,
  takeEvery,
  fork,
  select
} from '@redux-saga/core/effects'
import * as appreciationService from 'src/services/AppreciationService'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import { SEVERITY } from 'src/utils/toast'
import { pathOr } from 'ramda'
import { fetchAuthUserRoutine } from 'src/features/auth/duck/actions'
import { getAppreciationErrorMessage } from 'src/features/appreciations/duck/errors'
import { getOrganisationPointsGiveMinimum } from 'src/features/organisation/duck/selectors'

// ROUTINES

export const createAppreciationRoutine = createRoutine('CREATE_APPRECIATION')
export const fetchAllAppreciationsRoutine = createRoutine(
  'FETCH_ALL_APPRECIATIONS'
)
export const fetchGivenByAuthUserAppreciationsRoutine = createRoutine(
  'FETCH_GIVEN_BY_AUTH_USER_APPRECIATIONS'
)
export const fetchReceivedByAuthUserAppreciationsRoutine = createRoutine(
  'FETCH_RECEIVED_BY_AUTH_USER_APPRECIATIONS'
)
export const createReactionToAppreciationRoutine = createRoutine(
  'CREATE_REACTION_TO_APPRECIATION'
)
export const removeReactionFromAppreciationRoutine = createRoutine(
  'REMOVE_REACTION_FROM_APPRECIATION'
)
export const fetchUserAppreciationCountersRoutine = createRoutine(
  'FETCH_USER_APPRECIATION_COUNTERS'
)

// ACTIONS

function* createAppreciation({ payload }) {
  yield put(createAppreciationRoutine.request())
  try {
    const result = yield call(appreciationService.createAppreciation, payload)
    yield put(createAppreciationRoutine.success(pathOr({}, ['data'], result)))
    yield put(
      showToastRoutine({
        key: 'toast.createAppreciationSuccess',
        severity: SEVERITY.success
      })
    )
    yield put(fetchAuthUserRoutine())
    yield put(fetchUserAppreciationCountersRoutine())
  } catch (e) {
    yield put(createAppreciationRoutine.failure(e))
    yield put(
      showToastRoutine(
        getAppreciationErrorMessage(e, {
          minimumPoints: yield select(getOrganisationPointsGiveMinimum)
        })
      )
    )
    console.error(e)
  }
}

function* fetchAllAppreciations({ payload }) {
  yield put(fetchAllAppreciationsRoutine.request())
  try {
    const result = yield call(
      appreciationService.fetchAllAppreciations,
      payload
    )
    yield put(
      fetchAllAppreciationsRoutine.success(pathOr({}, ['data'], result))
    )
  } catch (e) {
    yield put(fetchAllAppreciationsRoutine.failure(e))
    console.error(e)
  }
}

function* fetchGivenByAUthUserAppreciations({ payload }) {
  yield put(fetchGivenByAuthUserAppreciationsRoutine.request())
  try {
    const result = yield call(
      appreciationService.fetchGivenByAUthUserAppreciations,
      payload
    )
    yield put(
      fetchGivenByAuthUserAppreciationsRoutine.success(
        pathOr({}, ['data'], result)
      )
    )
  } catch (e) {
    yield put(fetchGivenByAuthUserAppreciationsRoutine.failure(e))
    console.error(e)
  }
}

function* fetchReceivedByAuthUserAppreciations({ payload }) {
  yield put(fetchReceivedByAuthUserAppreciationsRoutine.request())
  try {
    const result = yield call(
      appreciationService.fetchReceivedByAuthUserAppreciations,
      payload
    )
    yield put(
      fetchReceivedByAuthUserAppreciationsRoutine.success(
        pathOr({}, ['data'], result)
      )
    )
  } catch (e) {
    yield put(fetchReceivedByAuthUserAppreciationsRoutine.failure(e))
    console.error(e)
  }
}

function* createReactionToAppreciation({ payload }) {
  yield put(createReactionToAppreciationRoutine.request())
  try {
    const result = yield call(
      appreciationService.createReactionToAppreciation,
      payload
    )
    yield put(
      createReactionToAppreciationRoutine.success(pathOr({}, ['data'], result))
    )
    yield put(fetchAuthUserRoutine())
  } catch (e) {
    yield put(createReactionToAppreciationRoutine.failure(e))
    yield put(
      showToastRoutine(
        getAppreciationErrorMessage(e, {
          minimumPoints: yield select(getOrganisationPointsGiveMinimum)
        })
      )
    )
    console.error(e)
  }
}

function* removeReactionFromAppreciation({ payload }) {
  yield put(removeReactionFromAppreciationRoutine.request())
  try {
    yield call(appreciationService.removeReactionFromAppreciation, payload)
    yield put(removeReactionFromAppreciationRoutine.success(payload))
    yield put(fetchAuthUserRoutine())
  } catch (e) {
    yield put(removeReactionFromAppreciationRoutine.failure(e))
    yield put(
      showToastRoutine(
        getAppreciationErrorMessage(e, {
          minimumPoints: yield select(getOrganisationPointsGiveMinimum)
        })
      )
    )
    console.error(e)
  }
}

function* fetchUserAppreciationCounters() {
  yield put(fetchUserAppreciationCountersRoutine.request())
  try {
    const result = yield call(appreciationService.fetchUserAppreciationCounters)
    yield put(
      fetchUserAppreciationCountersRoutine.success(pathOr({}, ['data'], result))
    )
  } catch (e) {
    yield put(fetchUserAppreciationCountersRoutine.failure(e))
    console.error(e)
  }
}

// WATCHERS

export function* createAppreciationWatcher() {
  yield takeLatest(createAppreciationRoutine.TRIGGER, createAppreciation)
}

export function* fetchAllAppreciationsWatcher() {
  yield takeEvery(fetchAllAppreciationsRoutine.TRIGGER, fetchAllAppreciations)
}

export function* fetchGivenByAUthUserAppreciationsWatcher() {
  yield takeEvery(
    fetchGivenByAuthUserAppreciationsRoutine.TRIGGER,
    fetchGivenByAUthUserAppreciations
  )
}

export function* fetchReceivedByAuthUserAppreciationsWatcher() {
  yield takeEvery(
    fetchReceivedByAuthUserAppreciationsRoutine.TRIGGER,
    fetchReceivedByAuthUserAppreciations
  )
}

export function* createReactionToAppreciationWatcher() {
  yield takeLatest(
    createReactionToAppreciationRoutine.TRIGGER,
    createReactionToAppreciation
  )
}

export function* removeReactionFromAppreciationWatcher() {
  yield takeEvery(
    removeReactionFromAppreciationRoutine.TRIGGER,
    removeReactionFromAppreciation
  )
}

export function* fetchUserAppreciationCountersWatcher() {
  yield takeEvery(
    fetchUserAppreciationCountersRoutine.TRIGGER,
    fetchUserAppreciationCounters
  )
}

// SAGAS

export const appreciationSagas = [
  fork(createAppreciationWatcher),
  fork(fetchAllAppreciationsWatcher),
  fork(fetchGivenByAUthUserAppreciationsWatcher),
  fork(fetchReceivedByAuthUserAppreciationsWatcher),
  fork(createReactionToAppreciationWatcher),
  fork(removeReactionFromAppreciationWatcher),
  fork(fetchUserAppreciationCountersWatcher)
]
